import { dashStyles, sessionStyles, tabledStyles } from "../../theme";
import { adaptV4Theme } from '@mui/material/styles';
import {
  Fab,
  Tooltip,
  Popover,
  FormGroup,
  Switch,
  Grid,
  Typography,
  IconButton,
  TextField,
  Snackbar,
  Color,
  AlertColor,
} from "@mui/material";
import { Settings, Close, DateRange } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { ViewType } from "../../services/viewParsing";
import { getDashboard } from "../../services/api";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useMediaQuery from "@mui/material/useMediaQuery";
import pt from 'date-fns/locale/pt';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { returnDate } from "../../services/dateParsing";
import { format } from "date-fns";
import { Alert } from "@mui/material";
import { ptBR } from "@mui/material/locale";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, } from '@mui/material/styles';
import { setValueJSON } from "services/auth";


declare module '@mui/styles' {
  
  interface DefaultTheme extends Theme {}
}

function Dashboard() {

  const intervalValues = [
    "Hoje", "Semana Atual", "Semana Anterior", "Mês Atual", "Mês Anterior", "Semestre", "Ano Atual", "Ano (365 dias)", "Personalizado"
  ];

  const materialTheme = (theme: any) => createTheme({
    ...theme,
    palette:{
      primary: {
        main: "#2B96D2",
      },
    },
    components: {
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          color: '#1565c0',
          borderRadius: 0,
          borderWidth: 0,
          borderColor: '#2196f3',
          border: '0px solid',
          backgroundColor: '#bbdefb',
        }
      }
    }
  }}, ptBR);

  //HOOKS
  const dashClasses = dashStyles();
  const tableClasses = tabledStyles();
  const sessionClasses = sessionStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dateEl, setDateEl] = useState<HTMLButtonElement | null>(null);
  const [viewList, setViewList] = useState([]);
  const [switchList, setSwitchList] = useState([]);
  const [initialDate, initialDateChange] = useState(new Date());
  const [finalDate, finalDateChange] = useState(new Date());
  const [datePicked, setDatePicked] = useState("");
  const [dateArray, setDateArray] = useState([]);
  const [currentEdit, setCurrentEdit] = useState("");
  const [responsefunction, setresponse] = useState([]);


  //POPOVER 
  const open = Boolean(anchorEl);
  const openDate = Boolean(dateEl);
  const id = open ? "config-popover" : undefined;
  const dateId = openDate ? "date-popover" : undefined;

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [openSnack, setOpenSnack] = useState(false);

  //SNACKBAR

  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  //FETCH DATA
  function setFields() {
    getDashboard().then((response) => {
      setSwitchList(
        response.results.map((value: any) => {
          value.status = true;
          value.name = value.name;
          value.id = value.url;
          return value;
        })
      );
      setDateArray(response.results.filter((element: any) => element.dateRequired === true).map((value: any) => {
        let dates = returnDate(intervalValues[value.defaultInterval]);
        return {
          interval: value.defaultInterval,
          url: value.url,
          initial: dates[0],
          final: dates[1],
        }
      }));
      setViewList(response.results.map((value: any) => {
        if (value.dateRequired) {
          let getDates = returnDate(intervalValues[value.defaultInterval]);
          value.dateUrl = value.url + `?startDate=${format(getDates[0], "yyyy-MM-dd")}&endDate=${format(getDates[1], "yyyy-MM-dd")}`
          return value
        } else {
          return value;
        }
      }));
      refreshDash();
    });
  }

  function refreshDash() {
    getDashboard().then(async (response) => {
      await setValueJSON("dashboard", response);
    });
  }

  //HANDLERS

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

  };

  function handleFinalDate(date: Date, value?: string | null) {
      finalDateChange(date);
      setDatePicked(intervalValues[8]);
      let editIndex = dateArray.findIndex(element => element.url === currentEdit);
      let newDateArray = [...dateArray];
      newDateArray[editIndex].interval = 8;
      newDateArray[editIndex].final = date;
      setDateArray(newDateArray);
      let changeUrl = viewList.findIndex(value => value.url === currentEdit);
      let newList = viewList;
      newList[changeUrl].dateUrl = `${newList[changeUrl].url}?startDate=${format(initialDate, "yyyy-MM-dd")}&endDate=${format(date, "yyyy-MM-dd")}`;
      setViewList(newList);
  }

  function handleInitialDate(date: Date, value?: string | null) {
      initialDateChange(date);
      setDatePicked(intervalValues[8]);
      let editIndex = dateArray.findIndex(element => element.url === currentEdit);
      let newDateArray = [...dateArray];
      newDateArray[editIndex].interval = 8;
      newDateArray[editIndex].initial = date;
      setDateArray(newDateArray);
      let changeUrl = viewList.findIndex(value => value.url === currentEdit);
      let newList = viewList;
      newList[changeUrl].dateUrl = `${newList[changeUrl].url}?startDate=${format(date, "yyyy-MM-dd")}&endDate=${format(finalDate, "yyyy-MM-dd")}`;
      setViewList(newList);
  }

  const handleClickDate = (event: React.MouseEvent<HTMLButtonElement>, props: any) => {
    let editIndex = dateArray.findIndex(element => element.url === props.url);
    setDatePicked(intervalValues[dateArray[editIndex].interval]);
    let dates;
    if (dateArray[editIndex].interval === 8) {
      dates = [dateArray[editIndex].initial, dateArray[editIndex].final]
    } else {
      dates = returnDate(intervalValues[dateArray[editIndex].interval]);
    }
    setCurrentEdit(props.url);
    initialDateChange(dates[0]);
    finalDateChange(dates[1]);
    setDateEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDate = () => {
    setDateEl(null);
  };

  function dateHandler(event: React.ChangeEvent<{ value: any }>) {
    if (event.target.value === "Personalizado") {
      setDatePicked(event.target.value);
    } else {
      setDatePicked(event.target.value);
      let changeView = dateArray.findIndex(value => value.url === currentEdit);
      let newDateArray = dateArray;
      newDateArray[changeView].interval = intervalValues.findIndex(value => value === event.target.value);
      let dates = returnDate(event.target.value);
      initialDateChange(dates[0]);
      finalDateChange(dates[1]);
      newDateArray[changeView].initial = dates[0];
      newDateArray[changeView].final = dates[1];
      setDateArray(newDateArray);
      let changeUrl = viewList.findIndex(value => value.url === currentEdit);
      let newList = viewList;
      newList[changeUrl].dateUrl = `${newList[changeUrl].url}?startDate=${format(dates[0], "yyyy-MM-dd")}&endDate=${format(dates[1], "yyyy-MM-dd")}`
      setViewList(newList);
    }
  }


  function handleOnDragEnd(result: any) {
    if (!result.destination) return;
    const items = viewList;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setViewList(items);
    setSwitchList(
      viewList.map((value: any) => {
        value.name = value.name;
        value.id = value.url;
        return value;
      })
    );
  }

  const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let editValues = [...switchList];
    let y = editValues.findIndex((x: any) => x.id === event.target.name);
    if (event.target.value === "true") {
      editValues[y].status = false;
    } else {
      editValues[y].status = true;
    }
    setViewList(editValues);
  };

  const buttonHandler = (target: any) => {
    let editValues = [...switchList];
    let y = editValues.findIndex((x: any) => x.name === target);
    editValues[y].status = false;
    setViewList(editValues);
  }

  //USEEFFECT AS INITIAL FETCH
  useEffect(() => {
    setFields();
  }, []);

  return (
    <div style={{ width: "100%", display: "flex", height: "100%" }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="view">
          {(provided) => (
            <Grid
              container
              spacing={2}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {viewList.map((value, index, array) => {
                let dates = {
                  initial: 0,
                  final: 0,
                };
                if(value.dateRequired){
                  dates = dateArray.find(e => e.url === value.url);
                }
                return (
                  <Draggable
                    key={value.url}
                    draggableId={value.url}
                    index={index}
                    isDragDisabled={true}
                  >
                    {(provided) => (
                      <Grid
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={value.status ? "" : sessionClasses.hideField}
                        key={value.url}
                        item
                        lg={value.width.large}
                        md={value.width.medium}
                        sm={value.width.small}
                        xs={12}
                      >
                        <div
                          className={useMediaQuery("(max-width: 600px)") ? dashClasses.chartWrapperMobile : dashClasses.chartWrapper}

                        >
                          <div style={{ position: "relative" }}>
                            <div style={useMediaQuery("(max-width: 600px)") ? { position: "static", marginLeft: "auto", display: "flex", justifyContent: "flex-end" } : { position: "absolute", right: -25, top: -25 }}>
                              <IconButton
                                className={value.dateRequired ? "" : sessionClasses.hideField}
                                aria-label="daterange"
                                onClick={event => handleClickDate(event, value)}
                                size="large">
                                <DateRange />
                              </IconButton>
                              <IconButton
                                aria-label="close"
                                onClick={() => buttonHandler(value.name)}
                                size="large">
                                <Close />
                              </IconButton>
                            </div>
                            <Typography className={value.dateRequired ? "" : dashClasses.typoView} align="center" variant={useMediaQuery("(max-width: 600px)") ? "h6" : "h5"}>
                              {value.name}
                            </Typography>
                            <Typography className={value.dateRequired ? dashClasses.typoView : sessionClasses.hideField} variant="body1" align="center">de {format(dates.initial, "dd/MM/yyyy")} até {format(dates.final, "dd/MM/yyyy")}</Typography>
                          </div>

                          <ViewType
                            height={value.height}
                            type={value.type}
                            url={value.dateRequired ? value.dateUrl : value.url}
                            name={value.name}
                            expiration={value.expiration}
                            mask={value.mask}
                          /></div>

                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>

      <Popover
        id={dateId}
        open={openDate}
        anchorEl={dateEl}
        onClose={handleCloseDate}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div  style={{
          height: "auto", display: "flex",
          flexDirection: "column",
          padding: "20px",
        }}>
          <Typography  align="center" variant="body2" style={{marginBottom: "10px"}} >Filtrar por data</Typography>
          <TextField
            style={{ margin: "10px 0px" }}
            variant="outlined"
            id="outlined"
            select
            name="defaultInterval"
            value={datePicked}
            onChange={dateHandler}
            label="Filtro de data"
            SelectProps={{
              native: true,
            }}
          >
            {intervalValues.map((option: any) => (
              <option hidden={option === "Personalizado"} key={option} value={option}>
                {option}
              </option>
            ))}
          </TextField>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={materialTheme} >
            <div style={{
              position: "relative",
              zIndex: 100,
              margin: "5px",
            }}>
              <DatePicker
                format="dd/MM/yyyy"
                label="Data de início"
                value={initialDate}
                maxDate={finalDate}                
                onChange={date => initialDateChange(date)}
                onAccept={date => handleInitialDate(date)}
              />
              </div>
              <div style={{
              position: "relative",
              zIndex: 100,
              margin: "5px",
            }}>
              <DatePicker
                format="dd/MM/yyyy"
                label="Data final"
                value={finalDate}
                minDate={initialDate}
                onAccept={date => handleFinalDate(date)}
                onChange={date => finalDateChange(date)}
              />
              </div>
            </ThemeProvider>
          </StyledEngineProvider>
          </LocalizationProvider>
        </div>
      </Popover>

      <Tooltip arrow title="Configurar">
        <Fab
          onClick={handleClick}
          className={tableClasses.fabIcon}
          color="primary"
          aria-label="add"
        >
          <Settings />
        </Fab>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <div className={dashClasses.popoverRoot} style={{ height: "auto"}}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="switches">
              {(provided) => (
                <FormGroup {...provided.droppableProps} ref={provided.innerRef}>
                  {switchList.map((option, index) => (
                    <Draggable
                      key={option.id}
                      draggableId={option.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div
                            className={dashClasses.switchWrapper}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="body1">
                              {option.name}
                            </Typography>
                            <Switch
                              key={option.id}
                              value={option.status}
                              name={option.id}
                              color="primary"
                              checked={option.status}
                              onChange={switchHandler}
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </FormGroup>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Popover>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleCloseSnack} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Dashboard;
